<template>
  <div class="staff height1">
    <!--      头部      -->
    <bread></bread>
    <!-- <div v-else class="iq-top-navbar">
            <el-page-header @back="goBack" content="下级账号">
            </el-page-header>
        </div> -->
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item>
                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px" @change="search"> </el-date-picker>
              </el-form-item>
              <el-form-item label="部门">
                <el-cascader :options="DepartmentData" :props="{ expandTrigger: 'hover', checkStrictly: true, value: 'id', label: 'name', children: 'childrens' }" :clearable="true" style="width: 100%" filterable @change="handleOption" size="small"></el-cascader>
              </el-form-item>
              <el-form-item>
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="用户名/账号" @keyup.enter.native="search" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="search()">搜索</el-button>
                <el-button type="primary" size="small" @click="AddUserBtn" plain>添加员工</el-button>
                <el-button type="primary" size="small" @click="BatchUpBtn()" plain>批量升级</el-button>
                <el-button type="primary" size="small" @click="BatchRenewalBtn()" plain>批量续费</el-button>
                <el-button type="warning" size="small" @click="BatchSettingBtn()" plain>批量门诊设置</el-button>
                <el-button type="warning" size="mini" class="el-icon-download" @click="downloadTemplate">下载模板</el-button>
              </el-form-item>
              <el-form-item>
                <el-upload class="upload" :show-file-list="false" accept=".xlsx,.xls" :auto-upload="true" :headers="headers" :action="fileUploadUrl" :on-success="uploadSuccess">
                  <el-button size="mini" type="success" class="el-icon-upload2">导入</el-button>
                </el-upload>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table :data="UserData" stripe style="width: 100%" ref="multipleTable" height="calc(100% - 90px)" :header-cell-style="{ background: '#F5F7FC' }">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="userName" label="账号" width="110" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.userName }}</p>
              <p>{{ scope.row.phone }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="daysRemaining" label="剩余天数" align="center">
            <template slot-scope="scope">
              <p v-if="scope.row.daysRemaining <= 0">0</p>
              <p v-else-if="scope.row.daysRemaining > 0 && scope.row.daysRemaining <= 60" style="color: red">
                {{ scope.row.daysRemaining }}
              </p>
              <p v-else>{{ scope.row.daysRemaining }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="versionsName" label="用户版本" align="center">
            <template slot-scope="scope">
              <el-tag size="small">{{ scope.row.versionsName }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="department.name" label="所属部门" align="center"> </el-table-column>
          <!-- <el-table-column prop="region.fullName" label="所在区域" align="center">
                    </el-table-column> -->
          <el-table-column prop="userPost.userPostName" label="职位" align="center"> </el-table-column>
          <!-- <el-table-column label="团长" align="center">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.isCommander" type="success">是</el-tag>
                            <el-tag v-else type="info">否</el-tag>
                        </template>
                    </el-table-column> -->
          <!-- <el-table-column prop="integral" label="积分" align="center"> </el-table-column> -->
          <!-- <el-table-column prop="consumptionTotal" label="消费金额（元）" align="center"> </el-table-column>
          <el-table-column prop="vipGrade" label="会员等级" align="center">
            <template slot-scope="scope">
              <el-tag size="small" v-if="scope.row.vipGrade == 0">普通会员</el-tag>
              <el-tag size="small" v-if="scope.row.vipGrade == 1" type="warning">VIP1</el-tag>
              <el-tag size="small" v-if="scope.row.vipGrade == 2" type="success">VIP2</el-tag>
            </template>
          </el-table-column> -->
          <el-table-column prop="isMakeDiagnose" label="自主开方权限" align="center" v-if="ClientId == YunClient || ClientId == TotalClient || ClientId == AssociationClient">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.isMakeDiagnose" active-color="#13ce66" inactive-color="#ff4949" :active-value="true" :inactive-value="false" @change="changeIsMakeDiagnose(scope.row)" v-if="(ClientId == YunClient || ClientId == AssociationClient) && scope.row.ifUploadCertificate == 1"> </el-switch>
              <p style="color: #ff0000" v-if="(ClientId == YunClient || ClientId == AssociationClient) && scope.row.ifUploadCertificate != 1">请上传资质证书</p>
              <el-switch v-model="scope.row.isMakeDiagnose" active-color="#13ce66" inactive-color="#ff4949" :active-value="true" :inactive-value="false" @change="changeIsMakeDiagnose(scope.row)" v-if="ClientId == TotalClient"> </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="isAuditDiagnose" label="处方审核权限" align="center" v-if="ClientId == YunClient || ClientId == TotalClient || ClientId == AssociationClient">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.isAuditDiagnose" active-color="#13ce66" inactive-color="#ff4949" :active-value="true" :inactive-value="false" @change="changeAuditDiagnose(scope.row)" v-if="(ClientId == YunClient || ClientId == AssociationClient) && scope.row.ifUploadCertificate == 1"> </el-switch>
              <p style="color: #ff0000" v-if="(ClientId == YunClient || ClientId == AssociationClient) && scope.row.ifUploadCertificate != 1">请上传资质证书</p>
              <el-switch v-model="scope.row.isAuditDiagnose" active-color="#13ce66" inactive-color="#ff4949" :active-value="true" :inactive-value="false" @change="changeAuditDiagnose(scope.row)" v-if="ClientId == TotalClient"> </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="注册时间" align="center" width="150px">
            <template slot-scope="scope">
              <p>{{ scope.row.createTime | timefilters }}</p>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="240" align="center">
            <template slot-scope="scope">
              <span class="info optionBtn" @click="EditUserBtn(scope.row)">编辑</span>
              <span class="danger optionBtn" @click="Unbind(scope.row)" text>解绑</span>
              <span class="success optionBtn" @click="ResetPwd(scope.row)" text>密码重置</span>
              <el-popover placement="top-start" :width="ClientId == TotalClient || ClientId == YunClient? 200 : 400" trigger="hover">
                <div class="moreBtn">
                  <template v-if="ClientId != TotalClient && ClientId != YunClient">
                    <span v-if="scope.row.distributorChildrensNum > 0" class="warning optionBtn" @click="toSubDis(scope.row, 1)" plain>直接下级</span>
                    <span v-else class="warningDis optionBtn">直接下级</span>
                    <span v-if="scope.row.distributorChildrensNum > 0" class="danger optionBtn" @click="toSubDis(scope.row, 0)" plain>所有下级</span>
                    <span v-else class="dangerDis optionBtn"> 所有下级</span>
                    <!-- <span class="warning optionBtn" @click="addPayMoney(scope.row)" text>增加消费金额</span> -->
                  </template>
                  <span class="primary optionBtn" @click="UpgradeBtn(scope.row)" text>升级版本</span>
                  <span class="warning optionBtn" @click="RenewalBtn(scope.row)" text>续费</span>
                  <span class="primary optionBtn" @click="AddRate(scope.row)" text v-if="ClientId == YunClient || ClientId == AssociationClient || ClientId == TotalClient">门诊设置</span>
                </div>
                <template slot="reference">
                  <span class="primary optionBtn" text>更多</span>
                </template>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="StaffTotal" layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
    <!--    Dialog 新增    -->
    <el-drawer class="el-drawer" :direction="direction" :size="size" custom-class="demo-drawer" :visible.sync="AddUserDialog">
      <h1 slot="title" v-html="DialogName"></h1>
      <div class="addhospital">
        <el-form :model="AddUserData" :rules="rules" ref="AddUserData" label-width="100px">
          <el-row :gutter="30">
            <el-col :span="8">
              <div class="form-box" style="padding-bottom: 50px">
                <div class="headImg">
                  <div class="headimgBox">
                    <el-upload :headers="headers" :action="fileUploadUrl" list-type="picture-card" :limit="1" class="avatar-uploader" :on-success="handleSuccess" :file-list="imageUrl" :on-remove="handleImageRemove" accept=".png,.jpg,.jpeg,.gif">
                      <img src="../../../assets/img/login_img.png" alt="" />
                    </el-upload>
                  </div>
                </div>
                <el-form-item label="职称" prop="userTitleId" v-if="ClientId == YunClient || ClientId == TotalClient || ClientId == AssociationClient">
                  <el-cascader
                    :options="userTitleData"
                    :props="{
                      expandTrigger: 'hover',
                      emitPath: false,
                      value: 'id',
                      label: 'userTitleName',
                      children: 'children',
                    }"
                    v-model="AddUserData.userTitleId"
                    style="width: 100%"
                    clearable
                  ></el-cascader>
                </el-form-item>
                <el-form-item label="职称" v-else>
                  <el-cascader
                    :options="userTitleData"
                    :props="{
                      expandTrigger: 'hover',
                      emitPath: false,
                      value: 'id',
                      label: 'userTitleName',
                      children: 'children',
                    }"
                    v-model="AddUserData.userTitleId"
                    style="width: 100%"
                    clearable
                  ></el-cascader>
                </el-form-item>
                <el-form-item label="职位" prop="userPostId">
                  <el-select v-model="AddUserData.userPostId" style="width: 100%" clearable placeholder="请选择用户职位">
                    <el-option v-for="item in UserPostData" :key="item.id" :label="item.userPostName" :value="item.id"> </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="所属部门">
                  <el-cascader
                    :options="DepartmentData"
                    :props="{
                      expandTrigger: 'hover',
                      emitPath: false,
                      checkStrictly: true,
                      value: 'id',
                      label: 'name',
                      children: 'childrens',
                    }"
                    v-model="AddUserData.departmentId"
                    style="width: 100%"
                    clearable
                  ></el-cascader>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="16">
              <el-scrollbar class="left-nav-wrap" style="height: 820px">
                <div class="form-box">
                  <span>员工资料</span>
                  <el-divider></el-divider>
                  <el-row>
                    <el-col :span="12">
                      <el-form-item label="姓名" prop="userName">
                        <el-input v-model="AddUserData.userName" autocomplete="off"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="用户昵称" prop="userName">
                        <el-input v-model="AddUserData.userName" autocomplete="off"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="联系电话" prop="phone">
                        <el-input v-model="AddUserData.phone" autocomplete="off" disabled> </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="性别" prop="sex">
                        <el-select v-model="AddUserData.sex" style="width: 100%" placeholder="性别">
                          <el-option label="男" :value="1"></el-option>
                          <el-option label="女" :value="0"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="生日">
                        <el-date-picker v-model="AddUserData.birthday" style="width: 100%" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"> </el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="学历">
                        <el-select v-model="AddUserData.userQualificationId" style="width: 100%" clearable placeholder="请选择用户学历">
                          <el-option v-for="item in UserQualificationData" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24" v-if="ClientId == YunClient">
                      <el-form-item label="证书编号">
                        <el-input v-model="AddUserData.certificateNumber" placeholder="证书编号"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="联系地址" prop="regionId">
                        <el-cascader
                          :placeholder="AddUserData.region ? AddUserData.region.fullName : '请选择所在区域'"
                          v-model="AddUserData.regionId"
                          :options="RegionData"
                          size="medium"
                          :props="{
                            emitPath: false,
                            multiple: false,
                            checkStrictly: true,
                            lazy: true,
                            lazyLoad: LasyLoadRegion,
                            value: 'id',
                            label: 'regionName',
                            children: 'childrens',
                          }"
                          style="margin-right: 10px; width: 100%; margin-bottom: 22px"
                        >
                        </el-cascader>
                        <el-input v-model="AddUserData.address" placeholder="请输入详细地址"> </el-input>
                      </el-form-item>
                    </el-col>
                    <!-- <el-col :span="12">
                      <el-form-item label="电子邮箱" prop="mailAddress">
                        <el-input v-model="AddUserData.mailAddress" placeholder="请输入电子邮箱"></el-input>
                      </el-form-item>
                    </el-col> -->
                    <el-col :span="24">
                      <el-form-item label="简介">
                        <el-input type="textarea" v-model="AddUserData.profile" placeholder="请输入简介"></el-input>
                      </el-form-item>
                    </el-col>
                    <!-- <el-col :span="12">
                                            <el-form-item label="是否设为团长" prop="userStatu">
                                                <el-radio-group size="medium" v-model="AddUserData.isCommander" aria-disabled>
                                                    <el-radio-button :label="true">是</el-radio-button>
                                                    <el-radio-button :label="false">否</el-radio-button>
                                                </el-radio-group>
                                            </el-form-item>
                                        </el-col> -->
                    <el-col :span="24">
                      <el-form-item label="个人介绍">
                        <quill-editor v-model="AddUserData.abstract" />
                      </el-form-item>
                    </el-col>
                    <el-col :span="24" v-if="ClientId == YunClient || ClientId == AssociationClient">
                      <el-form-item label="资格证书" required>
                        <el-upload :headers="headers" :action="fileUploadUrl" list-type="picture-card" :limit="5" multiple :on-preview="handleImagePreview" :on-remove="handleImageRemove1" :on-success="handleImageUpload" :file-list="ImageContent">
                          <!-- <el-image v-if="AddUserData.certificateImage" :src="`${this.CommonPath}${AddUserData.certificateImage}`" class="avatar" fit="contain"/> -->
                          <i class="el-icon-plus">
                            <!-- <Plus /> -->
                          </i>
                          <div class="el-upload__tip" slot="tip">最多上传5张</div>
                        </el-upload>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="ClientId == YunClient || ClientId == AssociationClient">
                      <el-form-item label="身份证正面" prop="idCardFront">
                        <el-upload ref="idCardFront" :headers="headers" :action="fileUploadUrl" class="avatar-uploader" :limit="1" list-type="picture-card" :show-file-list="false" :on-success="uploadIDFrontImage" accept=".png,.jpg,.jpeg,.gif">
                          <el-image v-if="AddUserData.idCardFront" :src="`${this.CommonPath}${AddUserData.idCardFront}`" class="avatar" fit="contain" />
                          <el-icon class="avatar-uploader-icon" v-else>
                            <!-- <Plus /> -->
                          </el-icon>
                        </el-upload>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="ClientId == YunClient || ClientId == AssociationClient">
                      <el-form-item label="身份证反面" prop="idCardBack">
                        <el-upload ref="idCardBack" :headers="headers" :action="fileUploadUrl" class="avatar-uploader" :limit="1" list-type="picture-card" :show-file-list="false" :on-success="uploadIDBackImage" accept=".png,.jpg,.jpeg,.gif">
                          <el-image v-if="AddUserData.idCardBack" :src="`${this.CommonPath}${AddUserData.idCardBack}`" class="avatar" fit="contain" />
                          <el-icon v-else class="avatar-uploader-icon">
                            <!-- <Plus /> -->
                          </el-icon>
                        </el-upload>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <span>账户信息</span>
                  <el-divider></el-divider>
                  <el-row>
                    <el-col :span="24">
                      <el-col :span="12">
                        <el-form-item label="用户账号" prop="phone">
                          <el-input v-model="AddUserData.phone" autocomplete="off" disabled> </el-input>
                          <el-button type="text" style="position: absolute; top: -3px"><i class="el-icon-question" style="font-size: 18px"></i>登录账号为手机号</el-button>
                        </el-form-item>
                      </el-col>
                    </el-col>

                    <el-col :span="12">
                      <el-form-item label="登录密码" v-if="!AddUserData.id" prop="password">
                        <el-input v-model.trim="AddUserData.password" show-password autocomplete="off" :disabled="disabled"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="确认密码" v-if="!AddUserData.id" prop="confirmPwd">
                        <el-input v-model.trim="AddUserData.confirmPwd" show-password autocomplete="off" :disabled="disabled"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-checkbox v-model="AddUserData.checked" v-if="!AddUserData.id" style="margin-left: 32px" @change="IsChecked">使用默认密码 <span style="color: #0c9a9a">(默认为：123456)</span></el-checkbox>
                  </el-row>
                  <div slot="footer" class="dialog-footer">
                    <el-divider></el-divider>
                    <el-button @click="cancelDialog('AddUserData')">取 消</el-button>
                    <el-button type="primary" @click="SaveUser('AddUserData')">确 定</el-button>
                  </div>
                </div>
              </el-scrollbar>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-drawer>
    <!-- 续费 -->
    <el-dialog :title="DialogTitle" :visible.sync="setDialog">
      <el-form class="dialog-form" :model="RenewalForm" ref="RenewalForm" :label-width="formLabelWidth">
        <el-form-item label="当前续费账号" prop="userName">
          <el-input v-model="RenewalForm.phone" autocomplete="off" :disabled="formdisabled"></el-input>
        </el-form-item>
        <el-form-item label="当前剩余">
          <el-input v-model="RenewalForm.daysRemaining" autocomplete="off" :disabled="formdisabled"> </el-input>
        </el-form-item>
        <el-form-item label="选择时长" prop="years">
          <!-- <el-radio-group style="display: flex;margin-top: 11px;" v-model="RenewalForm.afterVersionId"
                        @change="selectVersionTime">
                        <el-radio v-for="(item, index) in durationData" :key="item.label" :label="index">
                            {{ item.duration }}<span v-if="item.priceType == 1">月</span><span
                                v-if="item.priceType == 2">季</span><span v-if="item.priceType == 3">年</span><span
                                v-if="item.priceType == 4">天</span>
                        </el-radio>
                    </el-radio-group> -->
          <el-radio-group style="display: flex; margin-top: 11px" v-model="RenewalForm.afterVersionId" @change="CalculationBtn">
            <el-radio v-for="item in durationData" :key="item.label" :label="item.id"> {{ item.duration }}<span v-if="item.priceType == 1">月</span><span v-if="item.priceType == 2">季</span><span v-if="item.priceType == 3">年</span><span v-if="item.priceType == 4">天</span> </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="续费时长">
          <el-input v-model="CalculationData.days" autocomplete="off" :disabled="formdisabled"></el-input>
        </el-form-item>
        <el-form-item label="应付金额">
          <el-input v-model="CalculationData.sumPayable" autocomplete="off" :disabled="formdisabled"> </el-input>
        </el-form-item>
        <el-form-item label="实付金额">
          <el-input v-model="CalculationData.payAmount" autocomplete="off" :disabled="formdisabled"> </el-input>
        </el-form-item>
        <!-- <el-form-item label="选择支付方式">
                    <el-radio-group style="display: flex;margin-top: 11px;" v-model="payWay">
                        <el-radio label="1">微信支付</el-radio>
                        <el-radio label="3">对公账号支付</el-radio>
                    </el-radio-group>
                </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="CloseBtn('RenewalForm')">取 消</el-button>
        <el-button type="primary" @click="SaveRenewal('RenewalForm')">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 批量续费 -->
    <el-dialog :title="DialogTitle" :visible.sync="plsetDialog" @close="CloseBtn('plRenewalForm')">
      <el-form class="dialog-form" :model="plRenewalForm" ref="plRenewalForm" :label-width="formLabelWidth">
        <el-form-item label="已选账号数量" prop="userName">
          <el-input v-model="accountSum" autocomplete="off" :disabled="formdisabled"></el-input>
        </el-form-item>
        <el-form-item label="选择时长" prop="years">
          <el-radio-group style="display: flex; margin-top: 11px" v-model="plRenewalForm.afterVersionId" @change="CalculationBtn">
            <el-radio v-for="item in durationData" :key="item.label" :label="item.id"> {{ item.duration }}<span v-if="item.priceType == 1">月</span><span v-if="item.priceType == 2">季</span><span v-if="item.priceType == 3">年</span><span v-if="item.priceType == 4">天</span> </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="续费时长">
          <el-input v-model="CalculationData.days" autocomplete="off" :disabled="formdisabled"></el-input>
        </el-form-item>
        <el-form-item label="应付金额">
          <el-input v-model="CalculationData.sumPayable" autocomplete="off" :disabled="formdisabled"> </el-input>
        </el-form-item>
        <el-form-item label="实付金额">
          <el-input v-model="CalculationData.payAmount" autocomplete="off" :disabled="formdisabled"> </el-input>
        </el-form-item>
        <!-- <el-form-item label="选择支付方式">
                    <el-radio-group style="display: flex;margin-top: 11px;" v-model="payWay">
                        <el-radio label="1">微信支付</el-radio>
                        <el-radio label="3">对公账号支付</el-radio>
                    </el-radio-group>
                </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="CloseBtn('plRenewalForm')">取 消</el-button>
        <el-button type="primary" @click="SaveRenewal('plRenewalForm')">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 升级 -->
    <el-dialog :title="upTitle" :visible.sync="upDialog" @close="CloseBtn('upForm')">
      <el-form class="dialog-form" :model="upForm" ref="upForm" :label-width="formLabelWidth">
        <el-form-item label="当前续费账号" prop="userName">
          <el-input v-model="upForm.phone" autocomplete="off" :disabled="formdisabled"></el-input>
        </el-form-item>
        <el-form-item label="当前剩余">
          <el-input v-model="upForm.daysRemaining" autocomplete="off" :disabled="formdisabled"></el-input>
        </el-form-item>
        <el-form-item label="升级版本" prop="versionsId">
          <el-cascader v-model="upForm.versionsId" :options="VersionsData" :props="{ emitPath: false, value: 'id', label: 'name', children: 'children' }" @change="handleVersions"></el-cascader>
        </el-form-item>
        <el-form-item label="选择时长" prop="select" v-if="upForm.versionsId">
          <el-radio-group style="display: flex; margin-top: 11px" v-model="upForm.select" @change="selectVersionTime">
            <el-radio v-for="(item, index) in durationData" :key="item.label" :label="index"> {{ item.duration }}<span v-if="item.priceType == 1">月</span><span v-if="item.priceType == 2">季</span><span v-if="item.priceType == 3">年</span><span v-if="item.priceType == 4">天</span> </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="应付金额">
          <el-input v-model="CalculationData.sumPayable" autocomplete="off" :disabled="formdisabled"> </el-input>
        </el-form-item>
        <el-form-item label="已优惠金额">
          <el-input v-model="CalculationData.discountAmount" autocomplete="off" :disabled="formdisabled"> </el-input>
        </el-form-item>
        <el-form-item label="实付金额">
          <el-input v-model="CalculationData.payAmount" autocomplete="off" :disabled="formdisabled"> </el-input>
        </el-form-item>
        <!-- <el-form-item label="选择支付方式">
                    <el-radio-group style="display: flex;margin-top: 11px;" v-model="payWay">
                        <el-radio label="1">微信支付</el-radio>
                        <el-radio label="3">对公账号支付</el-radio>
                    </el-radio-group>
                </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="CloseBtn('upForm')">取 消</el-button>
        <el-button type="primary" @click="SaveRenewal('upForm')">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 批量升级 -->
    <el-dialog :title="upTitle" :visible.sync="plupDialog" @close="CloseBtn('plupForm')">
      <el-form class="dialog-form" :model="plupForm" ref="plupForm" :label-width="formLabelWidth" :rules="rules">
        <el-form-item label="已选账号数量">
          <el-input v-model="accountSum" autocomplete="off" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="升级版本" prop="versionsId">
          <el-cascader v-model="plupForm.versionsId" :options="VersionsData" :props="{ emitPath: false, value: 'id', label: 'name', children: 'children' }" @change="handleVersions"></el-cascader>
        </el-form-item>
        <el-form-item label="选择时长" prop="select" v-if="plupForm.versionsId">
          <el-radio-group style="display: flex; margin-top: 11px" v-model="plupForm.select" @change="selectVersionTime">
            <el-radio v-for="(item, index) in durationData" :key="item.label" :label="index"> {{ item.duration }}<span v-if="item.priceType == 1">月</span><span v-if="item.priceType == 2">季</span><span v-if="item.priceType == 3">年</span><span v-if="item.priceType == 4">天</span> </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="应付金额">
          <el-input v-model="CalculationData.sumPayable" autocomplete="off" :disabled="true"> </el-input>
        </el-form-item>
        <el-form-item label="已优惠金额">
          <el-input v-model="CalculationData.discountAmount" autocomplete="off" :disabled="true"> </el-input>
        </el-form-item>
        <el-form-item label="实付金额">
          <el-input v-model="CalculationData.payAmount" autocomplete="off" :disabled="true"> </el-input>
        </el-form-item>
        <!-- <el-form-item label="选择支付方式">
                    <el-radio-group style="display: flex;margin-top: 11px;" v-model="payWay">
                        <el-radio label="1">微信支付</el-radio>
                        <el-radio label="3">对公账号支付</el-radio>
                    </el-radio-group>
                </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="CloseBtn('plupForm')">取 消</el-button>
        <el-button type="primary" @click="SaveRenewal('plupForm')">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 判断手机号 -->
    <el-dialog :title="phoneTitle" :visible.sync="phoneDialog">
      <el-form class="dialog-form" :model="phoneForm" ref="phoneForm" :rules="phonerules" :label-width="formLabelWidth">
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="phoneForm.phone" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="phoneCloseBtn('phoneForm')">取 消</el-button>
        <el-button type="primary" @click="phoneSaveBtn('phoneForm')">确 定</el-button>
      </div>
    </el-dialog>
    <!--    支付    -->
    <el-dialog title="支付" :visible.sync="PaymentDialog" :before-close="handleClose">
      <div class="pay-top">
        <ul class="">
          <li class="payItem clearfix">
            <p>收款账户：</p>
            <span>河南千越医疗科技有限公司</span>
          </li>
          <li class="payItem clearfix">
            <p>收款日期：</p>
            <span>{{ gettime }}</span>
          </li>
          <li class="payItem clearfix">
            <p>应付金额：</p>
            <span class="payNum">{{ payMoney }}元</span>
          </li>
        </ul>
      </div>
      <el-divider></el-divider>
      <div class="pay-bg">
        <img :src="qRcodeUrl" alt="" />
      </div>
    </el-dialog>
    <!--    对公账户    -->
    <el-dialog title="支付" :visible.sync="BankcardDialog">
      <div class="pay-top">
        <ul class="">
          <li class="payItem clearfix">
            <p>收款账户：</p>
            <span>河南千越医疗科技有限公司</span>
          </li>
          <li class="payItem clearfix">
            <p>收款日期：</p>
            <span>{{ gettime }}</span>
          </li>
          <li class="payItem clearfix">
            <p>应付金额：</p>
            <span class="payNum">{{ payMoney }}元</span>
          </li>
        </ul>
      </div>
      <el-divider></el-divider>
      <el-descriptions title="请您使用公户支付应付金额！" :column="2" border>
        <el-descriptions-item label="开户名">河南千越医疗科技有限公司</el-descriptions-item>
        <el-descriptions-item label="账户信息">1711 0143 0910 0016 304</el-descriptions-item>
        <el-descriptions-item label="开户地址">中国工商银行漯河市青山路支行</el-descriptions-item>
        <el-descriptions-item label="备注信息">软件服务费</el-descriptions-item>
      </el-descriptions>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCopy()">复制信息</el-button>
        <el-button type="primary" @click="BankCloseBtn()">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 增加消费金额 -->
    <el-dialog title="增加消费金额" :visible.sync="addPayMoneyDialog">
      <el-form class="dialog-form" :model="addPayMoneyData" ref="addPayMoneyData" :label-width="formLabelWidth" :rules="rules">
        <el-form-item label="说明">
          <div>VIP1：1600-4499</div>
          <div>VIP2：大于4500</div>
        </el-form-item>
        <el-form-item label="消费金额" prop="money">
          <el-input v-model="addPayMoneyData.money" type="number"> </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addPayMoneyDialog = false">取 消</el-button>
        <el-button type="primary" @click="SavePayMoney('addPayMoneyData')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogTableVisible" :title="errMsg" :close-on-click-modal="false">
      <el-table :data="errUserData">
        <el-table-column prop="userName" label="用户名" />
        <el-table-column prop="phone" label="手机号" />
        <el-table-column prop="sex" label="性别">
          <template slot-scope="scope">
            <p v-if="scope.row.sex == 1">男</p>
            <p v-else>女</p>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 设置门诊抽佣比例 -->
    <el-dialog title="设置" :visible.sync="setRateDialog" width="30%">
      <el-form class="dialog-form" :model="formRate" ref="formRate" :label-width="formLabelWidth" :rules="rules">
        <el-form-item label="开处方抽佣比例" style="color: #ff0000" class="numT"> <el-input-number v-model="formRate.mZRate" size="small" controls-position="right" :precision="0" :step="1" :min="0" :max="35" class="leftInput" style="width: 150px; margin-right: 5px"></el-input-number>% </el-form-item>
        <el-form-item label="处方审核价格" style="color: #ff0000" class="numT"> <el-input-number v-model="formRate.auditDiagnoseMoney" size="small" controls-position="right" :precision="2" :step="1" :min="0.0" class="leftInput" style="width: 150px; margin-right: 5px"></el-input-number>元 </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="CloseRate('formRate')">取 消</el-button>
        <el-button type="primary" @click="saveRate('formRate')">确 定</el-button>
      </div>
    </el-dialog>

    <!--    批量门诊设置    -->
    <el-dialog :visible.sync="UserSettingDialog">
      <div class="table-top">
        <div class="iq-search-bar">
          <div class="flex-end" style="height: 55px">
            <el-button size="small" type="primary" @click="saveRateBatch()">保存</el-button>
          </div>
        </div>
      </div>
      <el-table :data="selectData" stripe style="width: 100%" height="440px">
        <el-table-column type="index" label="编号" width="50"></el-table-column>
        <el-table-column prop="userName" label="账号" width="110" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.userName }}</p>
            <p>{{ scope.row.phone }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="mzRate" label="开处方抽佣比例(%)" align="center">
          <template slot-scope="scope"> <el-input-number v-model="scope.row.mzRate" size="small" controls-position="right" :precision="0" :step="1" :min="0" :max="35" class="leftInput" style="width: 150px; margin-right: 5px"></el-input-number> </template>
        </el-table-column>
        <el-table-column prop="auditDiagnoseMoney" label="处方审核价格(元)" align="center">
          <template slot-scope="scope"> <el-input-number v-model="scope.row.auditDiagnoseMoney" size="small" controls-position="right" :precision="2" :step="1" :min="0.0" class="leftInput" style="width: 150px; margin-right: 5px"></el-input-number></template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import bread from '../../../components/common/breadcrumb'
import { Region } from '../../../components/HospitalDomain/Region'
import { Department } from '../../../components/HospitalDomain/Department'
import { UserPost } from '../../../components/HospitalDomain/UserPost'
import { UserTitle } from '../../../components/HospitalDomain/UserTitle'
import { UserQualification } from '../../../components/HospitalDomain/UserQualification'
import { User } from '../../../components/HospitalDomain/User'
import { Organization } from '../../../components/HospitalDomain/Organization'
import { Distributor } from '../../../components/HospitalDomain/Distributor'
import { Versions } from '../../../components/HospitalDomain/Versions'
import { RenewalUpgrade } from '../../../components/HospitalDomain/RenewalUpgrade'
import { WeChatPay } from '../../../components/HospitalDomain/WeChatPay'
import { createLogger } from 'vuex'
// var timer = "";
export default {
  inject: ['reload'],
  name: 'Staff',
  components: {
    bread,
  },
  computed: {
    headers() {
      return {
        Authorization: 'Bearer' + ' ' + JSON.parse(sessionStorage.getItem(this.ClientId + 'Token')).access_token,
      }
    },
  },
  data() {
    var region = new Region(this.TokenClient, this.Services.Authorization)
    var department = new Department(this.TokenClient, this.Services.Authorization)
    var userTitle = new UserTitle(this.TokenClient, this.Services.Authorization)
    var userPost = new UserPost(this.TokenClient, this.Services.Authorization)
    var userQualification = new UserQualification(this.TokenClient, this.Services.Authorization)
    var organization = new Organization(this.TokenClient, this.Services.Authorization)
    var user = new User(this.TokenClient, this.Services.Authorization)
    var distributor = new Distributor(this.TokenClient, this.Services.Authorization)
    var versions = new Versions(this.TokenClient, this.Services.Authorization)
    var renewalUpgrade = new RenewalUpgrade(this.TokenClient, this.Services.Authorization)
    var weChat = new WeChatPay(this.TokenClient, this.Services.Authorization)
    return {
      Region: region,
      RegionData: [],
      Department: department,
      UserTitle: userTitle,
      UserPost: userPost,
      UserQualification: userQualification,
      User: user,
      Organization: organization,
      DistributorDomain: distributor,
      VersionsDomain: versions,
      RenewalDomain: renewalUpgrade,
      WeChatPayDomain: weChat,
      searchForm: {
        statrTime: '',
        endTime: '',
        keyWord: '',
        datepicker: '',
        departmentId: 0,
      },
      keyWord: '',
      PageIndex: 1,
      PageTotal: 1,
      PageSize: 15,
      StaffTotal: 0,
      addPayMoneyData: {
        userId: '',
        money: 0,
      },
      addPayMoneyDialog: false,
      organizationId: null,
      userInfo: JSON.parse(sessionStorage.getItem('userInfo')),
      suborganizationId: this.$route.query.id,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      phoneForm: {
        phone: '',
      },
      phoneTitle: '验证手机号',
      phoneDialog: false,
      UserType: '',
      disabled: false,
      DepartmentData: [],
      UserPostData: [],
      userTitleData: [],
      UserQualificationData: [],
      UserData: [],
      DialogName: '',
      AddUserDialog: false,
      AddUserData: {
        id: 0,
        photoFile: '',
        userName: '',
        password: '',
        confirmPwd: '',
        phone: '',
        mailAddress: '',
        certificateNumber: '',
        birthday: new Date(),
        sex: 1,
        departmentId: null,
        userTitleId: null,
        roleId: 0,
        userStatu: 1,
        address: '',
        abstract: '',
        profile: '',
        certificateNumber: '',
        regionId: null,
        userQualificationId: null,
        checked: false,
        isCommander: false,
        userType: 1,
        idCardFront: '',
        idCardBack: '',
        certificateImage: '',
        isPharmacist: 0,
      },
      rules: {
        userName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/, message: '请输入合法手机号/电话号', trigger: 'blur' },
        ],
        mailAddress: [
          // { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
          {
            pattern: /^(?![\s\u4e00-\u9fa5]+$).{6,16}$/,
            message: '密码为6~16个英文字母、数字、符号组成！',
            trigger: 'blur',
          },
        ],
        confirmPwd: [{ required: true, message: '密码不能为空', trigger: 'blur' }],
        birthday: [
          { required: true, message: '请选择时间', trigger: 'blur' },
          { type: 'date', message: '请选择时间', trigger: 'blur' },
        ],
        departmentId: [{ required: true, message: '请选择所属部门', trigger: 'blur' }],
        regionId: [{ required: true, message: '请选择地区', trigger: 'blur' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'blur' }],
        userPostId: [{ required: true, message: '请选择用户职位', trigger: 'blur' }],
        userTitleId: [{ required: true, message: '请选择用户职称', trigger: 'blur' }],
        roleId: [{ required: true, message: '请选择用户角色', trigger: 'blur' }],
        versionsId: [{ required: true, message: '请选择版本', trigger: 'change' }],
        select: [{ required: true, message: '请选择版本时长', trigger: 'change' }],
        money: [
          { pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/, message: '小数点后保留两位', trigger: 'blur' },
          { required: true, message: '请输入正确的金额', trigger: 'blur' },
        ],
        mZRate: [{ required: true, message: '请输入比例', trigger: 'blur' }],
        auditDiagnoseMoney: [{ required: true, message: '请输入处方审核价格', trigger: 'blur' }],
        idCardFront: [{ required: true, message: '请上传图片', trigger: 'change' }],
        idCardBack: [{ required: true, message: '请上传图片', trigger: 'change' }],
      },
      phonerules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/, message: '请输入合法手机号/电话号', trigger: 'blur' },
        ],
      },
      VersionsData: [],
      durationData: [],
      formLabelWidth: '120px',
      direction: 'ttb',
      size: '100%',
      DialogTitle: '续费',
      setDialog: false,
      formdisabled: true,
      orderType: 0,
      RenewalForm: {},
      CalculationData: {
        sumPayable: 0,
        discountAmount: 0,
        payAmount: 0,
      },
      upTitle: '升级',
      upForm: {
        versionsId: '',
        select: 0,
      },
      upDialog: false,
      plsetDialog: false,
      plRenewalForm: {},
      plupDialog: false,
      plupForm: {
        versionsId: '',
        select: 0,
      },
      accountSum: 0,
      payMoney: 0,
      PaymentDialog: false,
      qRcodeUrl: '',
      gettime: '',
      weChatInfo: {},
      payWay: '1',
      BankcardDialog: false,
      fileUploadUrl: this.CommonFilePath,
      dialogTableVisible: false,
      errUserData: [],
      errMsg: '',
      setRateDialog: false,
      formRate: {
        id: 0,
        mZRate: 0,
        auditDiagnoseMoney: 0,
      },
      path: this.CommonPath,
      imageUrl: [],
      dialogImageUrl: '',
      dialogVisible: false,
      ImageContent: [], //图片列表
      ImageContentList: [],
      selectData: [],
      UserSettingDialog: false,
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.organizationId = parseInt(this.$route.query.id)
    } else {
      this.organizationId = JSON.parse(sessionStorage.getItem('userInfo')).organizationId
    }
    this.UserType = this.$route.query.Type ?? 'Manager'
    this.InitialRegion()
    this.InitialDepartment()
    this.InitialUserTitle()
    this.InitialUserPost()
    this.InitialUserQualification()
    this.InitialUser()
  },
  watch: {
    $route: function (to, from) {
      this.UserType = this.$route.query.Type ?? 'Manager'
      this.InitialUser()
    },
  },
  methods: {
    handleImagePreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleImageRemove1(file, fileList) {
      var _this = this
      let filePath = ''
      if (file.response) {
        filePath = file.response[0].completeFilePath
      } else {
        filePath = file.url.split(this.path)[1]
      }
      const i = _this.ImageContentList.findIndex((x) => x.filePath == filePath)
      _this.ImageContentList.splice(i, 1)
    },

    handleImageUpload(response, file, fileList) {
      var _this = this
      var imgarr = {
        filePath: response[0].completeFilePath,
        fileName: response[0].fileName,
      }
      _this.ImageContentList.push(imgarr)
    },
    uploadIDFrontImage(res, file) {
      this.AddUserData.idCardFront = `${res[0].completeFilePath}`.trim()
      this.$refs.idCardFront.clearFiles()
    },
    uploadIDBackImage(res, file) {
      this.AddUserData.idCardBack = `${res[0].completeFilePath}`.trim()
      this.$refs.idCardBack.clearFiles()
    },
    handleSuccess(response, file, fileList) {
      var _this = this
      response.forEach(function (file) {
        _this.AddUserData.photoFile = file.completeFilePath
      })
    },
    handleImageRemove(file, fileList) {
      this.AddUserData.photoFile = ''
    },
    handleOption(e) {
      this.searchForm.departmentId = e[e.length - 1]
      this.PageIndex = 1
      this.InitialUser()
    },
    CloseRate(formName) {
      this.setRateDialog = false
      this.$refs[formName].resetFields()
    },
    saveRate(formName) {
      var _this = this
      _this.User.SetMZUserRate(
        _this.formRate,
        function (data) {
          _this.$message({
            message: '设置成功',
            type: 'success',
          })
          _this.setRateDialog = false
          _this.reload()
        },
        function (error) {
          let jsonObj = JSON.parse(error.msg)
          _this.$message({
            message: jsonObj.msg,
            type: 'error',
          })
          console.log(error)
        }
      )
    },
    AddRate(item) {
      console.log(item)
      this.setRateDialog = true
      this.formRate.id = item.id
      this.formRate.mZRate = item.mzRate
      this.formRate.auditDiagnoseMoney = item.auditDiagnoseMoney
    },
    changeIsMakeDiagnose(item) {
      var _this = this
      _this.DistributorDomain.setMakeDiagnose(
        item.id,
        (data) => {
          _this.$message({
            type: 'success',
            message: '操作成功!',
          })
        },
        (err) => {
          console.log(err)
        }
      )
    },
    changeAuditDiagnose(item) {
      var _this = this
      _this.DistributorDomain.setAuditDiagnose(
        item.id,
        (data) => {
          _this.$message({
            type: 'success',
            message: '操作成功!',
          })
        },
        (err) => {
          console.log(err)
        }
      )
    },
    downloadTemplate() {
      let a = document.createElement('a')
      a.href = './UserTemplates.xlsx'
      a.download = '用户模板.xlsx'
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
    uploadSuccess(res, file) {
      var _this = this
      let filePath = `${res[0].fullUrl}`.trim()
      this.User.ImportUserData(
        filePath,
        function (data) {
          _this.$message({
            type: 'success',
            message: '导入成功!',
          })
          _this.PageIndex = 1
          _this.InitialUser()
        },
        function (err) {
          let jsonObj = JSON.parse(err.msg)
          console.log(jsonObj)
          _this.errMsg = `${jsonObj.msg}修改后重新导入。`
          _this.errUserData = jsonObj.data
          _this.dialogTableVisible = true
        }
      )
    },
    SavePayMoney(formName) {
      var _this = this
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.DistributorDomain.ConsumptionTotal(
            _this.addPayMoneyData,
            function (data) {
              _this.InitialUser()
              _this.addPayMoneyDialog = false
            },
            function (err) {
              var jsonObj = JSON.parse(err.msg)
              if (jsonObj.code == '400') {
                _this.$message({
                  type: 'error',
                  message: jsonObj.msg,
                })
              }
            }
          )
          _this.addPayMoneyDialog = false
        } else {
          return false
        }
      })
    },
    handleClose(done) {
      // clearInterval(timer)
      this.PaymentDialog = false
      this.RechargeDialog = false
    },
    InitialRegion() {
      var _this = this
      _this.Region.Regions(
        null,
        function (data) {
          _this.RegionData = data.data
        },
        function (err) {
          console.log(err)
        }
      )
    },
    LasyLoadRegion(node, resolve) {
      var _this = this
      _this.Region.Regions(
        node.value,
        function (data) {
          for (var dataIndex = 0; dataIndex < data.data.length; dataIndex++) {
            data.data[dataIndex].leaf = data.data[dataIndex].regionLevel >= 5
          }
          resolve(data.data)
        },
        function (err) {
          resolve([])
        }
      )
    },
    InitialDepartment() {
      var _this = this
      _this.Organization.DepartmentTree(
        _this.organizationId,
        false,
        function (data) {
          _this.DepartmentData = data.data
        },
        function (err) {
          console.log(err)
        }
      )
    },
    InitialUserPost() {
      var _this = this
      _this.UserPost.GetUserPost(
        this.organizationId,
        function (data) {
          _this.UserPostData = data.data
        },
        function (err) {
          console.log(err)
        }
      )
    },
    InitialUserTitle() {
      var _this = this
      _this.UserTitle.GetUserTitle(
        this.userInfo.productId,
        function (data) {
          _this.userTitleData = data.data
          const mapTree = (item) => {
            const haveChildren = Array.isArray(item.children) && item.children.length > 0
            return {
              ...item,
              children: haveChildren ? item.children.map((child) => mapTree(child)) : null,
            }
          }
          _this.userTitleData = _this.userTitleData.map((item) => mapTree(item))
        },
        function (err) {
          console.log(err)
        }
      )
    },
    InitialUserQualification() {
      var _this = this
      _this.UserQualification.Qualifications(
        function (data) {
          _this.UserQualificationData = data.data
        },
        function (err) {
          console.log(err)
        }
      )
    },
    ChangePage(pageIndex) {
      this.PageIndex = pageIndex
      this.InitialUser()
    },
    search() {
      this.PageIndex = 1
      this.InitialUser()
    },
    InitialUser() {
      var _this = this
      var item = _this.searchForm
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00'
        item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59'
      } else {
        item.startTime = ''
        item.endTime = ''
      }
      // if (_this.suborganizationId) {
      //     _this.organizationId = _this.suborganizationId
      // }
      _this.DistributorDomain.DistributorList(
        item.startTime,
        item.endTime,
        _this.organizationId,
        1,
        _this.PageIndex,
        item.keyWord,
        item.departmentId ? item.departmentId : 0,
        0,
        false,
        0,
        function (data) {
          _this.UserData = data.data.results
          _this.PageIndex = data.data.pageIndex
          _this.PageSize = data.data.pageSize
          _this.PageTotal = data.data.pageCount
          _this.StaffTotal = data.data.dataTotal
        },
        function (error) {
          console.log(error)
        }
      )
    },
    IsChecked(data) {
      var _this = this
      _this.disabled = data
      if (data) {
        _this.AddUserData.password = '123456'
        _this.AddUserData.confirmPwd = '123456'
      } else {
        _this.AddUserData.password = ''
        _this.AddUserData.confirmPwd = ''
      }
    },
    AddUserBtn() {
      var _this = this
      _this.DialogName = '新增员工'
      _this.AddUserData.id = 0
      _this.AddUserData.userName = ''
      _this.AddUserData.photoFile = ''
      _this.AddUserData.password = ''
      _this.AddUserData.confirmPwd = ''
      _this.AddUserData.checked = false
      _this.AddUserData.phone = ''
      _this.AddUserData.mailAddress = ''
      _this.AddUserData.birthday = new Date()
      _this.AddUserData.sex = 1
      _this.AddUserData.departmentId = null
      _this.AddUserData.userTitleId = null
      _this.AddUserData.organizationId = _this.organizationId
      _this.AddUserData.address = ''
      _this.AddUserData.abstract = ''
      _this.AddUserData.profile = ''
      _this.AddUserData.certificateNumber = ''
      _this.AddUserData.regionId = ''
      _this.AddUserData.userQualificationId = null
      _this.AddUserData.isDistributor = true
      _this.AddUserData.isCommander = false
      _this.AddUserData.userType = 1 //0普通用户（散户） 1 机构员工 2 思域会员
      _this.AddUserData.idCardFront = ''
      _this.AddUserData.idCardBack = ''
      _this.AddUserData.certificateImage = ''
      _this.AddUserData.isPharmacist = 0
      // if (_this.suborganizationId) {
      //     _this.AddUserData.organizationId = _this.suborganizationId;
      // }
      _this.phoneDialog = true
      _this.phoneForm.phone = ''
      _this.ImageContent = []
      _this.ImageContentList = []
    },
    EditUserBtn(i) {
      var _this = this
      _this.DialogName = '编辑员工'
      _this.User.StaffDetail(
        i.id,
        function (data) {
          var item = data.data
          _this.AddUserData = {
            id: item.id,
            roleId: item.roleId,
            userTitleId: item.userTitle ? item.userTitle.id : null,
            departmentId: item.department ? item.department.id : null,
            userName: item.userName,
            sex: item.sex,
            birthday: new Date(item.birthday),
            userQualificationId: item.userQualificationId ? item.userQualificationId : '',
            regionId: item.regionId,
            region: item.region,
            address: item.address,
            abstract: item.abstract,
            profile: item.profile,
            certificateNumber: item.certificateNumber,
            mailAddress: item.mail,
            userPostId: item.userPost ? item.userPost.id : null,
            doctorNo: item.doctorNo,
            telephone: item.telephone,
            photoFile: item.photoFile,
            phone: item.phone,
            organizationId: _this.organizationId,
            agentId: item.agentId,
            userType: 1,
            idCardFront: item.idCardFront,
            idCardBack: item.idCardBack,
            certificateImage: item.certificateImage,
            isPharmacist: item.isPharmacist,
          }
          let obj = new Object()
          _this.imageUrl = []
          if (item.photoFile) {
            obj.url = _this.CommonPath + item.photoFile
            _this.imageUrl.push(obj)
          }
          _this.ImageContent = []
          _this.ImageContentList = []
          if (item.certificateImage && item.certificateImage.split(',').length > 0) {
            let arry = item.certificateImage.split(',')
            arry.forEach((i) => {
              let obj = new Object()
              obj.url = _this.path + i
              _this.ImageContent.push(obj)
              let imgMap = {
                filePath: i,
              }
              _this.ImageContentList.push(imgMap)
            })
          }
        },
        function (err) {
          console.log(err)
        }
      )
      _this.AddUserDialog = true
    },
    SaveUser(formName) {
      var _this = this
      var item = _this.AddUserData
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ClientId == this.YunClient) {
            if (_this.ImageContentList.length == 0) {
              _this.$message({
                type: 'error',
                message: '请上传资质证书!',
              })
              return
            }
          }

          if (!item.birthday) {
            item.birthday = new Date()
          }
          item.sex = parseInt(item.sex)
          item.mail = item.mailAddress
          let imgList = []
          _this.ImageContentList.forEach((img) => {
            imgList.push(img.filePath)
          })
          item.certificateImage = imgList.join(',')
          if (item.id) {
            _this.User.Edit(
              item,
              function (data) {
                _this.AddUserDialog = false
                _this.reload()
              },
              function (err) {
                console.log(err)
              }
            )
          } else {
            if (_this.AddUserData.password == '') {
              this.$message.error('请输入密码')
              return false
            }
            if (_this.AddUserData.password != _this.AddUserData.confirmPwd) {
              this.$message.error('两次密码输入不同，请重新输入')
              return false
            }
            _this.User.Add(
              item,
              function (data) {
                _this.AddUserDialog = false
                _this.reload()
              },
              function (err) {
                var jsonObj = JSON.parse(err.msg)
                if (jsonObj.code == '400') {
                  _this.$message({
                    type: 'error',
                    message: jsonObj.msg,
                  })
                }
              }
            )
          }
          _this.AddUserDialog = false
        } else {
          return false
        }
      })
    },
    cancelDialog(formName) {
      var _this = this
      _this.AddUserDialog = false
      this.$refs[formName].resetFields()
    },
    phoneSaveBtn(e) {
      var _this = this
      _this.$refs[e].validate((valid) => {
        if (valid) {
          let item = {
            phone: _this.phoneForm.phone,
            userId: 0,
          }
          // item.birthday = DateToStr(item.birthday);
          _this.User.ExistUserAccount(
            item,
            function (data) {
              console.log(data)
              _this.phoneDialog = false
              if (data.code == 200) {
                _this.AddUserDialog = true
                _this.AddUserData.phone = _this.phoneForm.phone
              }
            },
            function (err) {
              _this
                .$confirm('该账号已经存在是否继续添加?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  // type: 'warning'
                })
                .then(() => {
                  var user = {
                    id: JSON.parse(err.msg).data.userId,
                  }
                  console.log(user)
                  _this.User.ExistUserOrganization(
                    _this.phoneForm.phone,
                    function (data) {
                      _this.phoneDialog = false
                      if (data.code == 200) {
                        _this.EditUserBtn(user)
                      }
                    },
                    function (err) {
                      var jsonObj = JSON.parse(err.msg)
                      _this.$message({
                        type: 'warning',
                        message: '该账号已存在',
                      })
                    }
                  )
                })
                .catch(() => {})
              console.log(err)
            }
          )
        } else {
          return false
        }
      })
    },
    phoneCloseBtn() {
      var _this = this
      _this.phoneDialog = false
    },
    Unbind(item) {
      var _this = this
      _this
        .$confirm('员工账号一旦解绑成功，该账号与本机构将无关联，是否继续您的操作?', '解绑提示', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'warning',
        })
        .then(() => {
          _this.User.unbindingUser(
            item.id,
            function (data) {
              if (data.code == 200) {
                _this.$message({
                  type: 'success',
                  message: '解绑成功!',
                })
                _this.InitialUser()
              }
            },
            function (err) {
              _this.$message({
                type: 'info',
                message: '解绑失败',
              })
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消解绑',
          })
        })
    },
    //单账号续费
    RenewalBtn(item) {
      var _this = this
      if (item.daysRemaining <= 0) {
        _this.$confirm('剩余天数小于0，无法进行续费操作', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          showCancelButton: false,
        })
      } else {
        _this.RenewalForm.phone = item.phone
        _this.RenewalForm.daysRemaining = item.daysRemaining > 0 ? item.daysRemaining : 0
        _this.orderType = 3
        _this.RenewalForm.beforeVersionId = item.roleId
        _this.RenewalForm.organizationId = item.organizationId
        _this.RenewalForm.userId = item.id
        _this.CalculationData = {}
        _this.RenewalDomain.Verifivation(
          [_this.RenewalForm.userId],
          function (res) {
            _this.setDialog = true
            _this.VersionsDomain.Duration(
              item.versionId,
              function (data) {
                if (data.code == 200) {
                  _this.durationData = data.data
                  _this.RenewalForm.afterVersionId = _this.durationData[0].id
                  _this.CalculationBtn()
                }
              },
              function (err) {}
            )
          },
          function (error) {
            var users = []
            var name = ''
            var err = error.data
            err.forEach((item) => {
              users = _this.UserData.filter((i) => i.id === item)
              users.forEach((k) => {
                name = name + k.userName + ' '
              })
            })
            _this.$confirm('员工：' + name + '存在未支付订单', '提示', {
              confirmButtonText: '我知道了',
              type: 'warning',
              showCancelButton: false,
            })
          }
        )
      }
    },
    //添加消费金额
    addPayMoney(item) {
      this.addPayMoneyData = {
        userId: item.id,
        money: 0,
      }
      this.addPayMoneyDialog = true
    },
    BatchSettingBtn() {
      var _this = this
      this.selectData = _this.$refs.multipleTable.selection
      if (this.selectData.length == 0) {
        _this.$message({
          type: 'error',
          message: '请选择要设置的员工',
        })
        return
      }
      this.UserSettingDialog = true
    },
    saveRateBatch() {
      var _this = this
      let list = this.selectData.map((item) => {
        return {
          id: item.id,
          mzRate: item.mzRate,
          auditDiagnoseMoney: item.auditDiagnoseMoney,
        }
      })
      _this.User.SetMZUserRateBatch(
        list,
        function (data) {
          _this.$message({
            message: '设置成功',
            type: 'success',
          })
          _this.UserSettingDialog = false
          _this.reload()
        },
        function (error) {
          let jsonObj = JSON.parse(error.msg)
          _this.$message({
            message: jsonObj.msg,
            type: 'error',
          })
          console.log(error)
        }
      )
    },
    //批量续费
    BatchRenewalBtn() {
      var _this = this
      const _selectData = _this.$refs.multipleTable.selection
      if (_selectData.length == 0) {
        _this.$message({
          type: 'error',
          message: '请选择要续费的员工',
        })
        return
      }
      let firstQdr = _selectData[0].versionId
      for (let index = 0; index < _selectData.length; index++) {
        let item = _selectData[index]
        if (item.versionId != firstQdr || item.daysRemaining <= 0) {
          _this.$confirm('批量续费所选用户版本不同或时长为0', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            showCancelButton: false,
          })
          return
        }
      }
      _this.orderType = 4
      _this.accountSum = _selectData.length
      _this.plRenewalForm.beforeVersionId = _selectData[0].roleId
      _this.plRenewalForm.organizationId = _selectData[0].organizationId
      _this.plRenewalForm.userId = _selectData.map((x) => {
        return x.id
      })
      _this.CalculationData = {}
      _this.RenewalDomain.Verifivation(
        _this.plRenewalForm.userId,
        function (res) {
          _this.plsetDialog = true
          _this.VersionsDomain.Duration(
            firstQdr,
            function (data) {
              if (data.code == 200) {
                _this.durationData = data.data
                _this.plRenewalForm.afterVersionId = _this.durationData[0].id
                _this.CalculationBtn()
              }
            },
            function (err) {}
          )
        },
        function (error) {
          var users = []
          var name = ''
          var err = error.data
          err.forEach((item) => {
            users = _this.UserData.filter((i) => i.id === item)
            users.forEach((k) => {
              name = name + k.userName + ' '
            })
          })
          _this.$confirm('员工：' + name + '存在未支付订单', '提示', {
            confirmButtonText: '我知道了',
            type: 'warning',
            showCancelButton: false,
          })
        }
      )
    },
    //单账号升级
    UpgradeBtn(item) {
      var _this = this
      _this.VersionsData = []
      _this.upForm.phone = item.phone
      _this.upForm.daysRemaining = item.daysRemaining > 0 ? item.daysRemaining : 0
      _this.orderType = 1
      _this.upForm.beforeVersionId = item.roleId
      _this.upForm.organizationId = item.organizationId
      _this.upForm.userId = item.id
      _this.RenewalDomain.Verifivation(
        [item.id],
        function (res) {
          _this.VersionsDomain.UpgradeVersion(
            item.versionId,
            function (data) {
              if (data.code == 200) {
                _this.VersionsData = data.data
                _this.upDialog = true
              }
            },
            function (err) {}
          )
        },
        function (error) {
          var users = []
          var name = ''
          var err = error.data
          err.forEach((item) => {
            users = _this.UserData.filter((i) => i.id === item)
            users.forEach((k) => {
              name = name + k.userName + ' '
            })
          })
          _this.$confirm('员工：' + name + '存在未支付订单', '提示', {
            confirmButtonText: '我知道了',
            type: 'warning',
            showCancelButton: false,
          })
        }
      )
    },
    handleVersions(value) {
      var _this = this
      if (_this.orderType == 1) {
        var versionId = _this.upForm.versionsId
      } else if (_this.orderType == 2) {
        var versionId = _this.plupForm.versionsId
      }
      _this.durationData = []
      _this.upForm.select = undefined
      _this.plupForm.select = undefined
      _this.upForm.afterVersionId = 0
      _this.plupForm.afterVersionId = 0
      _this.CalculationData = {}
      _this.VersionsDomain.Duration(
        versionId,
        function (data) {
          if (data.code == 200) {
            _this.durationData = data.data
            // _this.upForm.select = 0
            // _this.plupForm.select = 0
            _this.upForm.afterVersionId = _this.durationData[0].id
            _this.plupForm.afterVersionId = _this.durationData[0].id
            // _this.CalculationBtn()
          }
        },
        function (err) {}
      )
    },
    selectVersionTime(e) {
      var _this = this
      _this.upForm.afterVersionId = _this.durationData[e].id
      _this.plupForm.afterVersionId = _this.durationData[e].id
      _this.CalculationData = {}
      _this.CalculationBtn()
    },
    //批量升级
    BatchUpBtn() {
      var _this = this
      _this.VersionsData = []
      const _selectData = _this.$refs.multipleTable.selection
      if (_selectData.length == 0) {
        _this.$message({
          type: 'error',
          message: '请选择要升级的员工',
        })
        return
      }
      let firstQdr = _selectData[0].versionId
      let secondQdr = _selectData[0].daysRemaining
      for (let index = 0; index < _selectData.length; index++) {
        let item = _selectData[index]
        if (item.versionId != firstQdr || item.daysRemaining != secondQdr) {
          _this.$confirm('批量升级所选用户版本不同或剩余时长不同', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            showCancelButton: false,
          })
          return
        }
      }

      _this.orderType = 2
      _this.accountSum = _selectData.length
      _this.plupForm.beforeVersionId = _selectData[0].roleId
      _this.plupForm.organizationId = _selectData[0].organizationId
      _this.plupForm.userId = _selectData.map((x) => {
        return x.id
      })
      console.log(_this.plupForm.userId)
      _this.RenewalDomain.Verifivation(
        _this.plupForm.userId,
        function (res) {
          _this.VersionsDomain.UpgradeVersion(
            firstQdr,
            function (data) {
              if (data.code == 200) {
                _this.VersionsData = data.data
                _this.plupDialog = true
              }
            },
            function (err) {}
          )
        },
        function (error) {
          var users = []
          var name = ''
          var err = error.data
          err.forEach((item) => {
            users = _this.UserData.filter((i) => i.id === item)
            users.forEach((k) => {
              name = name + k.userName + ' '
            })
          })
          _this.$confirm('员工：' + name + '存在未支付订单', '提示', {
            confirmButtonText: '我知道了',
            type: 'warning',
            showCancelButton: false,
          })
        }
      )
    },
    //计算价格
    CalculationBtn() {
      var _this = this
      if (_this.orderType == 3) {
        var parmas = {
          orderType: _this.orderType,
          beforeRoleId: _this.RenewalForm.beforeVersionId,
          afterVersionId: _this.RenewalForm.afterVersionId,
          organizationId: _this.RenewalForm.organizationId,
          userIdArr: [_this.RenewalForm.userId],
        }
      } else if (_this.orderType == 4) {
        var parmas = {
          orderType: _this.orderType,
          beforeRoleId: _this.plRenewalForm.beforeVersionId,
          afterVersionId: _this.plRenewalForm.afterVersionId,
          organizationId: _this.plRenewalForm.organizationId,
          userIdArr: _this.plRenewalForm.userId,
        }
      } else if (_this.orderType == 1) {
        var parmas = {
          orderType: _this.orderType,
          beforeRoleId: _this.upForm.beforeVersionId,
          afterVersionId: _this.upForm.afterVersionId,
          organizationId: _this.upForm.organizationId,
          userIdArr: [_this.upForm.userId],
        }
      } else if (_this.orderType == 2) {
        // 升级
        var parmas = {
          orderType: _this.orderType,
          beforeRoleId: _this.plupForm.beforeVersionId,
          afterVersionId: _this.plupForm.afterVersionId,
          organizationId: _this.plupForm.organizationId,
          userIdArr: _this.plupForm.userId,
        }
      }
      _this.RenewalDomain.Calculation(
        parmas,
        function (data) {
          if (data.code == 200) {
            _this.CalculationData = data.data
          }
        },
        function (err) {
          var jsonObj = JSON.parse(err.msg)
          if (jsonObj.code == '400') {
            _this.$message({
              type: 'error',
              message: jsonObj.msg,
            })
          }
        }
      )
    },
    BankCloseBtn() {
      var _this = this
      _this.setDialog = false
      _this.plsetDialog = false
      _this.upDialog = false
      _this.plupDialog = false
      _this.BankcardDialog = false
      _this.RechargeDialog = false
    },
    CloseBtn(formName) {
      var _this = this
      _this.CalculationData = {}
      _this.$refs[formName].resetFields()
      _this.setDialog = false
      _this.plsetDialog = false
      _this.upDialog = false
      _this.plupDialog = false
      _this.BankcardDialog = false
      _this.RechargeDialog = false
    },
    //确定升级续费按钮
    SaveRenewal(e) {
      var _this = this
      _this.$refs[e].validate((valid) => {
        if (valid) {
          _this.CalculationData.payWay = _this.payWay
          _this.RenewalDomain.ConfirmOrder(
            _this.CalculationData,
            function (data) {
              if (data.code == 200) {
                _this.$message({
                  type: 'success',
                  message: '提交成功!',
                })
                _this.BankCloseBtn()
                _this.InitialUser()
              }
            },
            function (err) {
              var jsonObj = JSON.parse(err.msg)
              if (jsonObj.code == '400') {
                _this.$message({
                  type: 'error',
                  message: jsonObj.msg,
                })
              }
            }
          )
        }
      })
    },
    handleCopy() {
      var copyData = '支付金额：' + this.payMoney + '元；开户名：河南千越医疗科技有限公司；账户信息：1711 0143 0910 0016 304；开户地址：中国工商银行漯河市青山路支行；备注信息：软件服务费；'
      this.copy(copyData)
    },
    copy(data) {
      let url = data
      let oInput = document.createElement('input')
      oInput.value = url
      document.body.appendChild(oInput)
      oInput.select() // 选择对象;
      console.log(oInput.value)
      document.execCommand('Copy') // 执行浏览器复制命令
      this.$message({
        message: '复制成功',
        type: 'success',
      })
      oInput.remove()
    },
    upSaveBtn() {
      this.upDialog = false
    },
    upCloseBtn() {
      this.upDialog = false
    },
    ResetPwd(item) {
      var _this = this
      console.log(item)
      _this
        .$confirm('是否将该员工账号的密码恢复到初始化密码123456?', '密码重置提示', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'warning',
        })
        .then(() => {
          _this.User.ResetUserPassword(
            item.id,
            function (data) {
              _this.$message({
                type: 'success',
                message: '重置成功!',
              })
              _this.InitialUser()
            },
            function (err) {
              console.log(err)
            }
          )
        })
        .catch(() => {
          _this.$message({
            type: 'info',
            message: '已取消重置',
          })
        })
    },
    goBack() {
      this.$router.go(-1)
    },
    /**
     * type 1团长直接下级，0团长所有下级
     */
    toSubDis(item, type) {
      // const routerData = this.$router.resolve({
      //   path: '/User/SubStaff',
      //   query: {
      //     id: item.id,
      //     userName: item.userName,
      //     type: type,
      //     phone: item.phone,
      //   },
      // })
      // window.open(routerData.href, '_blank')
      this.$router.push({
        path: '/User/SubStaff',
        query: {
          id: item.id,
          userName: item.userName,
          type: type,
          phone: item.phone,
        },
      })
    },
  },
}
</script>

<style scoped>
.el-page-header {
  line-height: 73px;
  padding-left: 20px;
}

.dialog-form {
  width: 430px;
}

/**/
.addhospital {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.addhospital .el-form {
  flex: 1;
}

.addhospital .form-box {
  padding: 30px 50px 30px 30px;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
}

.demo-drawer__footer {
  display: flex;
  margin-left: 100px;
}

.el-drawer /deep/ .el-drawer {
  width: 100vw;
}

.headImg {
  position: relative;
  margin-bottom: 25px;
}

.headimgBox {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin: 0 auto;
  overflow: hidden;
  border: 5px solid rgba(12, 154, 154, 0.28);
}

.headimgBox img {
  width: 100%;
}

.headImg .upload-btn {
  position: absolute;
  left: 57%;
  bottom: 0px;
}

.pay-top {
  position: relative;
}

.payItem {
  line-height: 2;
}

.payItem p {
  float: left;
}

.payItem span {
  float: right;
}

.payNum {
  color: red;
  font-size: 24px;
}

.pay-bg {
  position: relative;
  margin: 20px auto;
  width: 350px;
  height: 436px;
  background: url('../../../assets/img/wx_pay.jpg') top center no-repeat;
  background-size: cover;
}

.pay-bg img {
  position: absolute;
  width: 160px;
  top: 140px;
  left: 100px;
}
.avatar {
  width: auto;
  height: 146px;
}
</style>
<style>
.staff .el-drawer {
  background-color: #eff7f8;
}

.el-select-dropdown__wrap.el-scrollbar__wrap {
  overflow: scroll !important;
}
</style>
